.math-quickset-style,
.math-question-style{
	padding: 5%;
	font: 13px Arial, Helvetica, sans-serif;
	border-top: 1px #0099ff solid;
	width: 580px;
	width: 80%;
}
.math-question-style-heading{
	font-weight: bold;
	font-style: italic;
	border-bottom: 2px solid #ddd;
	margin-bottom: 20px;
	font-size: 15px;
	padding-bottom: 3px;
	text-align: left;
}
.math-quickset-style div{
	margin: 0px 0px 6px 0px;
	flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 24px;
	flex-wrap: wrap;
}

.math-question-style div{
	display: block;
	margin: 0px 0px 15px 0px;
}

.math-question-style div > span{
	width: 100px;
	font-weight: bold;
	float: left;
	padding-top: 8px;
	padding-right: 5px;
}
.math-question-style span.required{
	color:red;
}
.math-question-style .number-range-field{
	width: 80px;
	text-align: center;
}
.math-question-style input.input-field, .math-question-style .select-field{
	width: 58%;	
}
.math-question-style input.input-field, 
.math-question-style .number-range-field, 
.math-question-style .textarea-field, 
 .math-question-style .select-field{
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	border: 1px solid #C2C2C2;
	box-shadow: 1px 1px 4px #EBEBEB;
	-moz-box-shadow: 1px 1px 4px #EBEBEB;
	-webkit-box-shadow: 1px 1px 4px #EBEBEB;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	padding: 7px;
	outline: none;
}
.math-question-style .input-field:focus, 
.math-question-style .number-range-field:focus, 
.math-question-style .textarea-field:focus,  
.math-question-style .select-field:focus{
	border: 1px solid #0C0;
}
.math-question-style .textarea-field{
	height:100px;
	width: 55%;
}
.math-question-style input[type=submit],
.math-question-style input[type=button]{
	border: none;
	padding: 8px 15px 8px 15px;
	margin-right: 10px;
	background: #0099ff;
	color: #fff;
	box-shadow: 1px 1px 4px #DADADA;
	-moz-box-shadow: 1px 1px 4px #DADADA;
	-webkit-box-shadow: 1px 1px 4px #DADADA;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
}
.math-question-style input[type=submit]:hover,
.math-question-style input[type=button]:hover{
	background: #00eac3;
	color: #fff;
}
