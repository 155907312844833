.zhuyin-style{
	padding: 20px 12px 10px 20px;
	font: 13px Arial, Helvetica, sans-serif;
	width: 100%;
}
.zhuyin-style-heading{
	font-weight: bold;
	font-style: italic;
	border-bottom: 2px solid #ddd;
	margin-bottom: 20px;
	font-size: 15px;
	padding-bottom: 3px;
	width: 100%;
}
.zhuyin-style div  button{
	height: 38px;
	margin-top: 8px !important;
}
.zhuyin-style .input-field:focus, 
.zhuyin-style .number-range-field:focus, 
.zhuyin-style .textarea-field:focus,  
.zhuyin-style .select-field:focus{
	border: 1px solid #0C0;
}
.zhuyin-style .textarea-field{
	height:100px;
	width: 55%;
}
.zhuyin-style input[type=submit],
.zhuyin-style input[type=button]{
	height: 38px;
	border: none;
	padding: 8px 15px 8px 15px;
	margin-left: 5px;
	margin-top: 16px;
	background: #0099ff;
	color: #fff;
	box-shadow: 1px 1px 4px #DADADA;
	-moz-box-shadow: 1px 1px 4px #DADADA;
	-webkit-box-shadow: 1px 1px 4px #DADADA;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
}
.zdj-style input[type=submit]:hover,
.zdj-style input[type=button]:hover{
	background: #00eac3;
	color: #fff;
}
