.question-list-style-right{
	padding: 20px 12px 10px 20px;
	font: 13px Arial, Helvetica, sans-serif;
}
.question-root{
	display: flex;
	flex-wrap: wrap;
}

.question-item{
	margin: 0px 28px 15px 0px;
    border: #ddd 1px solid;
    background-color: #e5eee6;
}
.buttons{
	margin: 0px 10px 15px 0px !important;
}

.buttons_hidden{
	margin: 0px 10px 15px 0px !important;
	display: none !important;
}

.question-list-style-right-heading{
	font-weight: bold;
	font-style: italic;
	border-bottom: 2px solid #ddd;
	margin-bottom: 20px;
	font-size: 15px;
	padding-bottom: 3px;
}
.question-item-no,
.question-item-check,
.question-item-answer,
.question-item-body{
    width: 25px;
	font-weight: bold;
	float: left;
	padding-top: 8px;
	padding-right: 5px;
}

.question-item-check{
	padding: 5px;
}

.question-item-body{
	width: 160px;
}

.question-item-answer{
    width: 80px;
	color:blue;
}

.question-list-style-right .number-range-field{
	width: 80px;
	text-align: center;
}
.question-list-style-right input.input-field, .question-list-style-right .select-field{
	width: 58%;	
}
.question-list-style-right input.input-field, 
.question-list-style-right .number-range-field, 
.question-list-style-right .textarea-field, 
 .question-list-style-right .select-field{
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	border: 1px solid #C2C2C2;
	box-shadow: 1px 1px 4px #EBEBEB;
	-moz-box-shadow: 1px 1px 4px #EBEBEB;
	-webkit-box-shadow: 1px 1px 4px #EBEBEB;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	padding: 7px;
	outline: none;
}

.question-list-style-right .number-range-field:focus{
	border: 1px solid #0C0;
}
